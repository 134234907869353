import React from 'react';

function InputFile({ handleFileChange, jenis_file, handleSubmit }) {
  return (
    <div>
      <form>
        <input
          type='file'
          onChange={handleFileChange}
          accept={jenis_file}
          className='p-2 mt-1 border border-gray-400 rounded-md w-full'
        />
      </form>
    </div>
  );
}

export default InputFile;
