import { React, useState } from 'react';
import Button from './Button';
function PopBnwb({ id, name, handleClose, SetProdukDipilih }) {
  const [ProductForm, setProductForm] = useState({
    article: '',
    AsalSell: name,
    code: '-',
    id: id,
    size: '',
    QtySell: 1,
    HargaSell: 0,
    OngkirSell: 0,
    Jenis: '',
  });

  const JenisProduk = [
    'Pilih Jenis',
    'BNIB',
    'BNWB',
    'BNIB GAGAN',
    'BNIB SKATE',
    'BNIB PRO',
    'BNIB NAVYA',
    'BNIB OLD STOCK',
    'BNIB OG',
    'LAINNYA',
  ];

  const addProduct = () => {
    const {
      QtySell,
      HargaSell,
      OngkirSell,
      AsalSell,
      article,
      code,
      id,
      size,
      Jenis,
    } = ProductForm;
    if (article !== '') {
      const newProduct = {
        article,
        AsalSell,
        code,
        id,
        size,
        QtySell,
        HargaSell,
        OngkirSell,
        Jenis,
      };

      SetProdukDipilih((prevState) => ({
        ...prevState,
        Produk: {
          ...prevState.Produk,
          [prevState.Counterproduk]: newProduct, // Gunakan Counterproduk sebagai kunci produk
        },
        Counterproduk: prevState.Counterproduk + 1, // Increment counter untuk produk berikutnya
      }));

      handleClose();
    } else {
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProductForm((prevValues) => ({
      ...prevValues,
      [name]: value.toUpperCase(),
    }));
  };

  return (
    <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
      <div className='bg-white p-8 rounded shadow-lg '>
        <div>
          <div className='flex space-x-6'>
            <div className=''>
              <h2 className='text-md mt-4'>Jenis Produk :</h2>
              <select
                name='Jenis'
                onChange={handleInputChange}
                value={ProductForm['jenisSell']}
                className='p-2 mt-1 border border-gray-400 rounded-md w-full'
              >
                {JenisProduk.map((produk, index) => (
                  <option key={index} value={produk}>
                    {produk}
                  </option>
                ))}
              </select>
            </div>
            <div className='w-full'>
              <h2 className='text-md mt-4'>Nama Produk :</h2>
              <input
                type='text'
                name='article'
                onChange={handleInputChange}
                value={ProductForm['article']}
                placeholder='Masukan Nama Produk..'
                className='p-2 mt-1 border border-gray-400 rounded-md w-full'
              />
            </div>
          </div>{' '}
          <div>
            <h2 className='text-md mt-4'>Size Produk :</h2>
            <input
              type='text'
              name='size'
              onChange={handleInputChange}
              value={ProductForm['size']}
              placeholder='Masukan Size Produk..'
              className='p-2 mt-1 border border-gray-400 rounded-md w-full'
            />
            <h2 className='text-md mt-4'>Qty Produk :</h2>
            <input
              type='number'
              name='QtySell'
              onChange={handleInputChange}
              value={ProductForm['QtySell']}
              min={1}
              defaultValue={1}
              placeholder='Qty Produk..'
              className='p-2 mt-1 border border-gray-400 rounded-md w-full'
            />
            <h2 className='text-md mt-4'>Harga Produk :</h2>
            <input
              type='number'
              name='HargaSell'
              onChange={handleInputChange}
              value={ProductForm['HargaSell']}
              min={0}
              defaultValue={0}
              placeholder='Harga Produk..'
              className='p-2 mt-1 border border-gray-400 rounded-md w-full'
            />
            <h2 className='text-md mt-4'>Harga Ongkir :</h2>
            <input
              type='number'
              name='OngkirSell'
              onChange={handleInputChange}
              value={ProductForm['OngkirSell']}
              min={0}
              defaultValue={0}
              placeholder='Harga Ongkir..'
              className='p-2 mt-1 border border-gray-400 rounded-md w-full'
            />
          </div>
          <div>
            {' '}
            <div className='flex space-x-6 mt-4 '>
              <Button label='Jual Produk' onClick={() => addProduct()} />
              <Button label='Batal' onClick={() => handleClose()} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopBnwb;
