import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import SellDropship from './pages/SellDropship';
import SellWholesale from './pages/SellWholesale';
import SellEcomRegular from './pages/SellEcomRegular';
import SellEcomLive from './pages/SellEcomLive';

function App() {
  return (
    <BrowserRouter>
      <div className='bg-slate-300 min-h-screen'>
        <Header />
        <Routes>
          <Route path='/' element={<SellDropship />}></Route>
          <Route path='/wholesale' element={<SellWholesale />}></Route>
          <Route
            path='/ecommerce-regular'
            element={<SellEcomRegular />}
          ></Route>
          <Route path='/ecommerce-live' element={<SellEcomLive />}></Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
