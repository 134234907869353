import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '../components/Button';
import PopBnib from '../components/PopBnib';
import PopBnwb from '../components/PopBnwb';
import TablePenjualan from '../components/TablePenjualan';
import TotalQty from '../components/TotalQty';
import InputFile from '../components/InputFile';
import PopConfirmation from '../components/PopConfirmation';
import PopLoading from '../components/PopLoading';
import PopSended from '../components/PopSended';

function SellDropship() {
  //* VARIABLE VARIABLE CONST*//
  const TipePage = 'DROPSHIP';
  const TipeSlug = 'ds';
  const BaseUrl = 'https://apiseller.sidehustle.id';
  const [SystemStatus, setSystemStatus] = useState(0);
  const [InformationAdmin, setInformationAdmin] = useState({
    info_admin: [
      {
        id: 0,
        name: '',
      },
    ],
    info_bank: [
      {
        bank: '',
        id: 0,
      },
    ],
    info_ekspedisi: [
      {
        ekspedisi: '',
        id: 0,
      },
    ],
    info_media: [
      {
        id: 0,
        media: '',
      },
    ],
  });
  const [FormKiri, setFormKiri] = useState({
    AdminName: '',
    Media: '',
    Rekening: '',
    JenisPengiriman: '',
    Ekspedisi: '',
    Toko: '',
    Alamat: '',
    Note: '',
  });

  const [ProdukDipilih, SetProdukDipilih] = useState({
    Counterproduk: 1,
    Produk: {},
  });
  const [ResponseSended, SetResponseSended] = useState({
    data: {
      admin: '-',
      invoice: '-',
    },
    date: '-',
    message: '-',
    status: '-',
  });

  const [IsPopLP, setIsPopLP] = useState(false);
  const [IsPopBK, setIsPopBK] = useState(false);
  const [IsPopBKNIKE, setIsPopBKNIKE] = useState(false);
  const [IsPopBKBNIB, setIsPopBKBNIB] = useState(false);
  const [IsPopBKAPA, setIsPopBKAPA] = useState(false);
  const [IsPopDS, setIsPopDS] = useState(false);
  const [IsPopCINERE, setIsPopCINERE] = useState(false);
  const [IsPopConfirm, setIsPopConfirm] = useState(false);
  const [IsPopLoading, setIsPopLoading] = useState(false);
  const [IsPopSended, setIsPopSended] = useState(false);

  //* FUNGSI FUNGSI*//

  const GetSystemStatus = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/sysstatus`);
      setSystemStatus(response.data['SystemStatus']);
    } catch (error) {
      console.log(error);
    }
  };

  const GetInformationAdmin = async () => {
    try {
      const response = await axios.get(
        `${BaseUrl}/getcapitols?type=${TipeSlug}`
      );
      setInformationAdmin(response.data['data']);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteProduct = (productId) => {
    // Dapatkan salinan produk dari state
    const updatedProducts = { ...ProdukDipilih.Produk };

    // Hapus produk dengan ID yang sesuai
    delete updatedProducts[productId];

    // Perbarui state dengan produk yang telah dihapus
    SetProdukDipilih((prevState) => ({
      ...prevState,
      Produk: updatedProducts,
    }));
  };

  const togglePopLP = () => {
    setIsPopLP(!IsPopLP);
  };
  const togglePopBK = () => {
    setIsPopBK(!IsPopBK);
  };
  const togglePopBKNIKE = () => {
    setIsPopBKNIKE(!IsPopBKNIKE);
  };
  const togglePopBKBNIB = () => {
    setIsPopBKBNIB(!IsPopBKBNIB);
  };
  const togglePopBKAPA = () => {
    setIsPopBKAPA(!IsPopBKAPA);
  };
  const togglePopDS = () => {
    setIsPopDS(!IsPopDS);
  };
  const togglePopCINERE = () => {
    setIsPopCINERE(!IsPopCINERE);
  };
  const togglePopConfirm = () => {
    setIsPopConfirm(!IsPopConfirm);
  };
  const togglePopSended = () => {
    setIsPopSended(!IsPopSended);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormKiri((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const [selectedTF, setselectedTF] = useState({ name: '' });
  const [selectedResi, setselectedResi] = useState({ name: '' });

  const handleTFChange = (event) => {
    setselectedTF(event.target.files[0]);
  };
  const handleResiChange = (event) => {
    setselectedResi(event.target.files[0]);
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    // Append form fields to FormData
    for (const key in FormKiri) {
      if (FormKiri[key] !== null) {
        // Hanya menambahkan nilai yang bukan null
        formData.append(key, FormKiri[key]);
      }
    }

    formData.append('Produk', JSON.stringify(ProdukDipilih['Produk']));

    // Append the selected file to FormData
    if (selectedResi) {
      formData.append('resi', selectedResi);
    }
    if (selectedTF) {
      formData.append('tf', selectedTF);
    }

    try {
      togglePopConfirm();
      setIsPopLoading(true);
      const response = await axios.post(`${BaseUrl}/jual?type=ds`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log(response.data);
      SetResponseSended(response.data);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsPopLoading(false);
      setIsPopSended(true);
      console.log(ResponseSended);
    }
  };

  useEffect(() => {
    GetSystemStatus();
    GetInformationAdmin();
  }, []);

  return (
    <div>
      <div className='mt-6 px-20'>
        <div className='flex space-x-6'>
          {/* Head untuk type dan status warehouse */}
          <div className='bg-white rounded-md shadow-md p-4'>
            <h1 className='font-bold text-xl'>{TipePage}</h1>
          </div>
          <div className='bg-white rounded-md shadow-md p-4'>
            {SystemStatus === 1 ? (
              <h1 className='font-bold text-xl text-green-500'>ONLINE</h1>
            ) : (
              <h1 className='font-bold text-xl text-red-600'>OFFLINE</h1>
            )}
          </div>
          {/* Form informasi admin dan pengiriman */}
        </div>
      </div>

      <div className='grid grid-cols-4 py-5 space-x-6 px-20'>
        <div className='space-y-6'>
          <div>
            <div className='bg-white rounded-md shadow-md py-7 px-7'>
              <div className=''>
                <h1 className='font-semibold text-md'>Informasi Admin :</h1>
                <h2 className='text-md mt-4'>Pilih Admin :</h2>
                <select
                  name={'AdminName'}
                  value={FormKiri['AdminName']}
                  onChange={handleInputChange}
                  className='p-2 mt-1 border border-gray-400 rounded-md w-full'
                >
                  <option value={''}>Pilih Admin</option>
                  {InformationAdmin.info_admin.map((item) => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option> // Sesuaikan dengan struktur data Anda
                  ))}
                </select>
                <h2 className='text-md mt-4'>Pilih Media :</h2>
                <select
                  name={'Media'}
                  value={FormKiri['Media']}
                  onChange={handleInputChange}
                  className='p-2 mt-1 border border-gray-400 rounded-md w-full'
                >
                  <option value={''}>Pilih Device</option>
                  {InformationAdmin.info_media.map((item) => (
                    <option key={item.id} value={item.media}>
                      {item.media}
                    </option> // Sesuaikan dengan struktur data Anda
                  ))}
                </select>
                <h2 className='text-md mt-4'>Pilih Rekening :</h2>
                <select
                  name={'Rekening'}
                  value={FormKiri['Rekening']}
                  onChange={handleInputChange}
                  className='p-2 mt-1 border border-gray-400 rounded-md w-full'
                >
                  <option value={''}>Pilih Rekening</option>
                  {InformationAdmin.info_bank.map((item) => (
                    <option key={item.id} value={item.bank}>
                      {item.bank}
                    </option> // Sesuaikan dengan struktur data Anda
                  ))}
                </select>
              </div>
              <div className='mt-8'>
                <h1 className='font-semibold text-md'>
                  Informasi Pengiriman :
                </h1>
                <h2 className='text-md mt-4'>Pilih Pengiriman :</h2>
                <select
                  name={'JenisPengiriman'}
                  value={FormKiri['JenisPengiriman']}
                  onChange={handleInputChange}
                  className='p-2 mt-1 border border-gray-400 rounded-md w-full'
                >
                  <option value={''}>Pilih Pengiriman</option>
                  <option value={'reguler'}>Reguler</option>
                  <option value={'cashless'}>Cashless</option>
                  <option value={'cod'}>COD</option>
                </select>
                {FormKiri.JenisPengiriman !== 'cod' && (
                  <div>
                    <h2 className='text-md mt-4'>Pilih Ekspedisi :</h2>{' '}
                    <select
                      name={'Ekspedisi'}
                      value={FormKiri['Ekspedisi']}
                      onChange={handleInputChange}
                      className='p-2 mt-1 border border-gray-400 rounded-md w-full'
                    >
                      <option value={''}>Pilih Ekspedisi</option>
                      {InformationAdmin.info_ekspedisi.map((item) => (
                        <option key={item.id} value={item.ekspedisi}>
                          {item.ekspedisi}
                        </option> // Sesuaikan dengan struktur data Anda
                      ))}
                    </select>
                  </div>
                )}
              </div>
              <div className='mt-8'>
                <h1 className='font-semibold text-md'>Informasi Penerima :</h1>
                <h2 className='text-md mt-4'>Nama Toko :</h2>
                <input
                  type='text'
                  name={'Toko'}
                  value={FormKiri['Toko']}
                  onChange={handleInputChange}
                  placeholder='Enter text'
                  className='p-2 mt-1 border border-gray-400 rounded-md w-full'
                />

                {FormKiri.JenisPengiriman !== 'cod' && (
                  <div>
                    <h2 className='text-md mt-4'>Alamat :</h2>
                    <textarea
                      type='textarea'
                      name={'Alamat'}
                      value={FormKiri['Alamat']}
                      onChange={handleInputChange}
                      placeholder='Enter text'
                      rows={5}
                      className='p-2 mt-1 border border-gray-400 rounded-md w-full'
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='col-span-3 bg-white rounded-md shadow-md py-7 px-7'>
          {SystemStatus === 1 ? (
            <div className='flex space-x-2'>
              <Button label='LP' onClick={() => togglePopLP()} />
              <Button label='BK' onClick={() => togglePopBK()} />
              <Button
                label='BK NIKE ADIDAS'
                onClick={() => togglePopBKNIKE()}
              />
              <Button label='BK BNIB' onClick={() => togglePopBKBNIB()} />
              <Button label='BK APPAREL' onClick={() => togglePopBKAPA()} />
              <Button label='DS' onClick={() => togglePopDS()} />
              <Button label='CINERE' onClick={() => togglePopCINERE()} />
            </div>
          ) : (
            <p></p>
          )}
          <div className='mt-5'>
            <div className='mb-5'>
              <TablePenjualan
                products={ProdukDipilih.Produk}
                onDeleteProduct={deleteProduct}
              />
            </div>
            <TotalQty products={ProdukDipilih.Produk} />
          </div>
          <div className='grid grid-cols-2 space-x-6 mt-5 w-2/3'>
            <div>
              <h1 className='font-semibold text-md'>Bukti Transfer (JPG) :</h1>
              <InputFile
                handleFileChange={handleTFChange}
                jenis_file='.jpg,.jpeg,.png'
                handleSubmit={handleSubmit}
              />
            </div>
            <div>
              <h1 className='font-semibold text-md'>Resi (PDF) :</h1>{' '}
              <InputFile
                handleFileChange={handleResiChange}
                jenis_file='.pdf,.jpg,.jpeg,.png'
                handleSubmit={handleSubmit}
              />
            </div>
          </div>
          <div className='w-2/3'>
            <h1 className='font-semibold text-md'>Note :</h1>{' '}
            <textarea
              type='textarea'
              name={'Note'}
              value={FormKiri['Note']}
              onChange={handleInputChange}
              placeholder='Enter text'
              className='p-2 mt-1 border border-gray-400 rounded-md w-full'
              rows={5}
            />
            <div className='mt-3'>
              {SystemStatus === 1 ? (
                <Button
                  label='Simpan Perubahan'
                  onClick={() => togglePopConfirm()}
                />
              ) : (
                <p>
                  *Segera hubungi tim warehouse, jika sistem offline lebih dari
                  30 menit
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      {IsPopLP && (
        <PopBnib
          id='1'
          name='LP'
          handleClose={togglePopLP}
          SetProdukDipilih={SetProdukDipilih}
        />
      )}
      {IsPopBK && (
        <PopBnwb
          id='1'
          name='BK'
          handleClose={togglePopBK}
          SetProdukDipilih={SetProdukDipilih}
        />
      )}
      {IsPopBKNIKE && (
        <PopBnib
          id='2'
          name='BK+'
          handleClose={togglePopBKNIKE}
          SetProdukDipilih={SetProdukDipilih}
        />
      )}
      {IsPopBKBNIB && (
        <PopBnib
          id='4'
          name='BK'
          handleClose={togglePopBKBNIB}
          SetProdukDipilih={SetProdukDipilih}
        />
      )}
      {IsPopBKAPA && (
        <PopBnib
          id='3'
          name='BK'
          handleClose={togglePopBKAPA}
          SetProdukDipilih={SetProdukDipilih}
        />
      )}
      {IsPopDS && (
        <PopBnwb
          id='1'
          name='DS'
          handleClose={togglePopDS}
          SetProdukDipilih={SetProdukDipilih}
        />
      )}
      {IsPopCINERE && (
        <PopBnib
          id='99'
          name='CINERE'
          handleClose={togglePopCINERE}
          SetProdukDipilih={SetProdukDipilih}
        />
      )}
      {IsPopConfirm && (
        <PopConfirmation
          handleClose={togglePopConfirm}
          FormKiri={FormKiri}
          selectedTF={selectedTF}
          selectedResi={selectedResi}
          ProdukDipilih={ProdukDipilih}
          handleSubmit={handleSubmit}
        />
      )}
      {IsPopLoading && <PopLoading />}
      {IsPopSended && (
        <PopSended
          ResponseSended={ResponseSended}
          togglePopSended={togglePopSended}
        />
      )}
    </div>
  );
}

export default SellDropship;
