import React from 'react';

function PopLoading() {
  return (
    <div className='fixed inset-0 flex items-center justify-center bg-white bg-opacity-90'>
      <div className=''>
        <div>
          <img
            src='/icons/loading.webp'
            alt='Icon'
            className='h-auto w-40 mx-auto'
          />
          <h1 className='text-center mx-auto'>
            Proses input data akan berlangsung 10-20 detik
          </h1>
        </div>
      </div>
    </div>
  );
}

export default PopLoading;
