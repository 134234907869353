import React from 'react';

function Header() {
  return (
    <div>
      <div className='grid grid-cols-2 py-6 px-8 bg-white shadow-md shadow-gray-400'>
        <div className='my-auto '>
          <img src='/sidehustle_seller.webp' className='h-auto w-1/3' alt='' />
        </div>
        <div className='my-auto space-x-8 text-right flex justify-end'>
          <a href='/' className='font-semibold text-xl'>
            Dropship
          </a>
          <a href='/wholesale' className='font-semibold text-xl'>
            Wholesale
          </a>
          <a href='/ecommerce-regular' className='font-semibold text-xl'>
            Ecommerce Regular
          </a>
          <a href='/ecommerce-live' className='font-semibold text-xl'>
            Ecommerce Live
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
