import React from 'react';
import Button from './Button';

function PopSended({ ResponseSended, togglePopSended }) {
  return (
    <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-60'>
      <div className='bg-white p-8 rounded shadow-lg  w-1/4'>
        {ResponseSended.status === 'success' ? (
          <div className='mx-auto'>
            <img
              src='/icons/done.gif'
              alt='Icon'
              className='h-auto w-40 mx-auto'
            />
            <h1 className='font-semibold text-xl text-center mb-3'>
              Selamat penjualan kamu sudah masuk
            </h1>
            <h1 className='font-semibold text-base text-center '>
              No. Invoice : {ResponseSended.data.invoice}
            </h1>
            <h1 className='font-semibold text-base text-center  mb-3'>
              {ResponseSended.date} WIB
            </h1>
            <p className='text-base text-center'>
              *jika ada kesalahan input data segera hubungi manager
              masing-masing
            </p>
            <div className='flex justify-center mt-5'>
              <Button label='Selesai' onClick={() => togglePopSended()} />
            </div>
          </div>
        ) : (
          <div className='mx-auto'>
            <img
              src='/icons/failed.gif'
              alt='Icon'
              className='h-auto w-40 mx-auto'
            />
            <h1 className='font-semibold text-xl text-center mb-3'>
              Penjualanmu Gagal
            </h1>
            <h1 className='font-semibold text-base text-center '>
              Screenshoot Halaman ini dan kirimkan ke Friza, <br></br> untuk
              pengecekan lebih lanjut
            </h1>
            <h1 className='font-semibold text-base text-center  mb-3'>
              {ResponseSended.date} WIB
            </h1>
            <p className='text-base text-center'>error code:</p>
            <p className='text-base text-center'>{ResponseSended.message}</p>
            <div className='flex justify-center mt-5'>
              <Button label='Selesai' onClick={() => togglePopSended()} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PopSended;
