import React from 'react';

function TableConfirmation({ products }) {
  const formatRupiah = (number) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    }).format(number);
  };
  return (
    <div>
      <table className='w-full table-auto bg-white border-collapse overflow-hidden rounded-md'>
        <thead>
          <tr>
            <th className='px-4 py-2 border border-gray-300 p-2 bg-blue-200 font-semibold text-md'>
              Asal
            </th>
            <th className='px-4 py-2 border border-gray-300 p-2 bg-blue-200 font-semibold text-md'>
              Article
            </th>
            <th className='px-4 py-2 border border-gray-300 p-2 bg-blue-200 font-semibold text-md'>
              Size
            </th>
            <th className='px-4 py-2 border border-gray-300 p-2 bg-blue-200 font-semibold text-md'>
              Qty
            </th>
            <th className='px-4 py-2 border border-gray-300 p-2 bg-blue-200 font-semibold text-md'>
              Harga
            </th>
            <th className='px-4 py-2 border border-gray-300 p-2 bg-blue-200 font-semibold text-md'>
              Ongkir
            </th>
            {/* Kolom baru untuk tombol hapus */}
          </tr>
        </thead>
        <tbody>
          {Object.keys(products).map((productId) => {
            const product = products[productId];
            return (
              <tr key={productId}>
                <td className='border px-4 py-2 border-gray-300'>
                  {product.AsalSell}
                </td>
                <td className='border px-4 py-2 border-gray-300'>
                  {product.article}
                </td>
                <td className='border px-4 py-2 border-gray-300'>
                  {product.size}
                </td>
                <td className='border px-4 py-2 border-gray-300 text-center'>
                  {product.QtySell}
                </td>
                <td className='border px-4 py-2 border-gray-300 text-right'>
                  {formatRupiah(product.HargaSell)}
                </td>
                <td className='border px-4 py-2 border-gray-300 text-right'>
                  {formatRupiah(product.OngkirSell)}
                </td>
                {/* Tombol hapus */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default TableConfirmation;
