import React from 'react';
import Button from './Button';
import TableConfirmation from './TableConfirmation';
import TotalQty from './TotalQty';

function PopConfirmation({
  FormKiri,
  handleClose,
  selectedTF,
  selectedResi,
  handleSubmit,
  ProdukDipilih,
}) {
  return (
    <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
      <div className='bg-white p-8 rounded shadow-lg  w-3/4'>
        <div>
          <h1 className='font-bold text-xl mb-2'>SUMMARY PESANAN</h1>
        </div>
        <div className='grid grid-cols-5 space-x-6'>
          <div className='col-span-2'>
            <tbody>
              <tr>
                <td className='border px-4 py-2 border-gray-300 font-semibold'>
                  Admin
                </td>
                <td className='border px-4 py-2 border-gray-300 text-left'>
                  {FormKiri['AdminName'].toUpperCase()}
                </td>
              </tr>
              <tr>
                <td className='border px-4 py-2 border-gray-300 font-semibold'>
                  Media
                </td>
                <td className='border px-4 py-2 border-gray-300 text-left'>
                  {FormKiri['Media'].toUpperCase()}
                </td>
              </tr>
              <tr>
                <td className='border px-4 py-2 border-gray-300 font-semibold'>
                  Rekening
                </td>
                <td className='border px-4 py-2 border-gray-300 text-left'>
                  {FormKiri['Rekening'].toUpperCase()}
                </td>
              </tr>
              <tr>
                <td className='border px-4 py-2 border-gray-300 font-semibold'>
                  Pengiriman
                </td>
                <td className='border px-4 py-2 border-gray-300 text-left'>
                  {FormKiri['JenisPengiriman'].toUpperCase()}
                </td>
              </tr>
              <tr>
                <td className='border px-4 py-2 border-gray-300 font-semibold'>
                  Ekspedisi
                </td>
                <td className='border px-4 py-2 border-gray-300 text-left'>
                  {FormKiri['Ekspedisi'].toUpperCase()}
                </td>
              </tr>
              <tr>
                <td className='border px-4 py-2 border-gray-300 font-semibold'>
                  Toko
                </td>
                <td className='border px-4 py-2 border-gray-300 text-left'>
                  {FormKiri['Toko'] || '-'}
                </td>
              </tr>
              <tr>
                <td className='border px-4 py-2 border-gray-300 font-semibold'>
                  Alamat
                </td>
                <td className='border px-4 py-2 border-gray-300 text-left'>
                  {FormKiri['Alamat'] || '-'}
                </td>
              </tr>
              <tr>
                <td className='border px-4 py-2 border-gray-300 font-semibold'>
                  Note
                </td>
                <td className='border px-4 py-2 border-gray-300 text-left'>
                  {FormKiri['Note'] || '-'}
                </td>
              </tr>
              <tr>
                <td className='border px-4 py-2 border-gray-300 font-semibold'>
                  Bukti TF
                </td>
                <td className='border px-4 py-2 border-gray-300 text-left'>
                  {selectedTF.name || '-'}
                </td>
              </tr>
              <tr>
                <td className='border px-4 py-2 border-gray-300 font-semibold'>
                  Resi
                </td>
                <td className='border px-4 py-2 border-gray-300 text-left'>
                  {selectedResi.name || '-'}
                </td>
              </tr>
            </tbody>
          </div>
          <div className='col-span-3 space-y-3'>
            <TableConfirmation products={ProdukDipilih['Produk']} />
            <TotalQty products={ProdukDipilih['Produk']} />
            <Button label='Jual Sekarang' onClick={() => handleSubmit()} />
            <Button label='Batal' onClick={() => handleClose()} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopConfirmation;
