import { React, useState, useEffect } from 'react';
import Button from './Button';
import axios from 'axios';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';

function PopBnib({ id, name, handleClose, SetProdukDipilih }) {
  const [Products, setProducts] = useState([]);
  const [SelectedProduct, setSelectedProduct] = useState({});
  const [DetailSell, setDetailSell] = useState({
    QtySell: '1',
    HargaSell: '0',
    OngkirSell: '0',
    Jenis: 'BNIB',
    AsalSell: name,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDetailSell((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const GetProducts = async (WarehouseId) => {
    try {
      const response = await axios.get(
        `https://apiwarehouse.sidehustle.id/getproductwp?warehouse=${WarehouseId}`
      );
      setProducts(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const addProduct = () => {
    const { article, code, id, size } = SelectedProduct;
    const { QtySell, HargaSell, OngkirSell, AsalSell, Jenis } = DetailSell;
    if (code !== undefined) {
      const newProduct = {
        article,
        AsalSell,
        code,
        id,
        size,
        QtySell,
        HargaSell,
        OngkirSell,
        Jenis,
      };

      SetProdukDipilih((prevState) => ({
        ...prevState,
        Produk: {
          ...prevState.Produk,
          [prevState.Counterproduk]: newProduct, // Gunakan Counterproduk sebagai kunci produk
        },
        Counterproduk: prevState.Counterproduk + 1, // Increment counter untuk produk berikutnya
      }));

      handleClose();
    } else {
    }
  };

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className='flex justify-content-end'>
        <span className='p-input-icon-left'>
          <i className='pi pi-search' />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder='Cari Produk'
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  useEffect(() => {
    GetProducts(id);
  }, [id]);

  return (
    <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
      <div className='bg-white p-8 rounded shadow-lg '>
        <div className='grid grid-cols-3 space-x-6'>
          <div className='col-span-2'>
            <DataTable
              selectionMode='single'
              selection={SelectedProduct}
              onSelectionChange={(e) => {
                if (e.value !== null) {
                  setSelectedProduct(e.value);
                }
              }}
              value={Products}
              showGridlines
              paginator
              rows={6}
              dataKey='code'
              filters={filters}
              header={header}
            >
              <Column field='code' header='Code' />
              <Column field='article' header='Article' />
              <Column field='size' header='Size' />
              <Column field='qty' header='Qty' />
            </DataTable>
          </div>
          <div className='col-span-1 mt-5'>
            <div>
              <h1 className='font-semibold text-md'>{name}</h1>
              <h1 className='font-semibold text-md'>Informasi Produk :</h1>
              <div className='flex'>
                <div className='mr-3'>
                  <h1>Kode Produk :</h1>
                  <h1>Artikel Produk :</h1>
                  <h1>Ukuran Produk :</h1>
                </div>
                <div>
                  <h1>{SelectedProduct['code']}</h1>
                  <h1>{SelectedProduct['article']}</h1>
                  <h1>{SelectedProduct['size']}</h1>
                </div>
              </div>
              <div>
                <h2 className='text-md mt-4'>Qty Produk :</h2>
                <input
                  type='number'
                  min={1}
                  defaultValue={1}
                  name={'QtySell'}
                  value={DetailSell['QtySell']}
                  onChange={handleInputChange}
                  placeholder='Masukan Kuantitas Terjual..'
                  className='p-2 mt-1 border border-gray-400 rounded-md w-full'
                />
                <h2 className='text-md mt-4'>Harga Produk :</h2>
                <input
                  type='number'
                  min={0}
                  defaultValue={0}
                  name={'HargaSell'}
                  value={DetailSell['HargaSell']}
                  onChange={handleInputChange}
                  placeholder='Masukan Harga Produk..'
                  className='p-2 mt-1 border border-gray-400 rounded-md w-full'
                />
                <h2 className='text-md mt-4'>Harga Ongkir :</h2>
                <input
                  type='number'
                  min={0}
                  defaultValue={0}
                  name={'OngkirSell'}
                  value={DetailSell['OngkirSell']}
                  onChange={handleInputChange}
                  placeholder='Masukan Harga Ongkir..'
                  className='p-2 mt-1 border border-gray-400 rounded-md w-full'
                />
                <div className='flex space-x-6 mt-4 '>
                  <Button label='Jual Produk' onClick={() => addProduct()} />
                  <Button label='Batal' onClick={() => handleClose()} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopBnib;
