import React from 'react';

function TotalQty({ products }) {
  const totalQty = Object.values(products).reduce((acc, curr) => {
    return acc + parseInt(curr.QtySell);
  }, 0);

  const totalCost = Object.values(products).reduce((acc, curr) => {
    return acc + parseInt(curr.HargaSell) + parseInt(curr.OngkirSell);
  }, 0);

  const formatRupiah = (number) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    }).format(number);
  };
  return (
    <div className='flex justify-end'>
      <table className='table-auto bg-white border-collapse overflow-hidden rounded-md justify-end'>
        <tbody>
          <tr>
            <td className='border px-4 py-2 border-gray-300 font-semibold'>
              Total Produk
            </td>
            <td className='border px-4 py-2 border-gray-300 text-right'>
              {totalQty} Pcs
            </td>
          </tr>
          <tr>
            <td className='border px-4 py-2 border-gray-300 font-semibold'>
              Total Harga
            </td>
            <td className='border px-4 py-2 border-gray-300'>
              {formatRupiah(totalCost)},00
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default TotalQty;
